/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { VendorSearchStore } from './vendor-search.store';
import { forkJoin, of, pipe } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {
  GetOpensearchVendorRequest,
  ISearchLocation,
  SupplierListModel
} from 'sustainment-component';
import { CapabilityQuery } from '../capability/capability.query';
import { MapSettingStore } from '../map-setting/map-setting.store';
import { MapSettingAction } from '../map-setting/map-setting.action';
import {
  VendorSearchApi,
  InteractionsApi,
  VendorFormatService
} from 'sustainment-component';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class VendorSearchActions {
  private _addLogos = pipe(
    mergeMap((response: SupplierListModel[]) => {
      if (response?.length) {
        response.map((r) => {
          r.logoUrl = this._vendorFormatService.getLogo(
            r?.sustainmentId,
            r.logo || ''
          );
        });
      }
      return of(response);
    })
  );

  private _updateConnectionStatus = pipe(
    mergeMap((result: SupplierListModel[]) => {
      forkJoin(
        result.map((v) => {
          if (v.inviteRequested) {
            return this._interactionsApi
              .getConnectionStatus(v.sustainmentId)
              .pipe(
                map(
                  (c): SupplierListModel => ({
                    ...v,
                    connected: c.status === 'Connected'
                  })
                )
              );
          }
          return of(v);
        })
      ).pipe(map((updated) => ({ data: updated, total: result.length })));
      return of(result);
    })
  );

  public constructor(
    private _store: VendorSearchStore,
    private _api: VendorSearchApi,
    private capabilityQuery: CapabilityQuery,
    private _mapSettingAction: MapSettingAction,
    private _mapSettingStore: MapSettingStore,
    private _vendorFormatService: VendorFormatService,
    private _interactionsApi: InteractionsApi,
    private _router: Router
  ) {}

  public updateText(text = ''): void {
    const storeValue = { ...this._store.getValue() };
    if (storeValue && storeValue.request) {
      storeValue.request = { ...storeValue.request, text: text };

      this._store.update({ request: storeValue.request });
    }
  }

  public setInitalSearch(initialSearch: boolean): void {
    this._store.update({ initialSearch });
    this._store.update({ initialSearchLocation: initialSearch });
  }

  public setOpensearchRequest(request: GetOpensearchVendorRequest): void {
    this.setTriggerOrigin();

    if (!request) return;

    let existingRequest = this._store.getValue()?.opensearchRequest;

    let initialSearch = this._store.getValue()?.initialSearch ?? false;

    if (!initialSearch) {
      if (request.filter && existingRequest?.filter != request.filter) {
        initialSearch = true;
      }

      if (
        request.locationNameFilter &&
        existingRequest?.locationNameFilter != request.locationNameFilter
      ) {
        initialSearch = true;
      }

      if (!request.locationNameFilter && existingRequest?.locationName)
        initialSearch = true;
    }

    let initialSearchLocation =
      this._store.getValue().initialSearchLocation ?? false;

    if (!initialSearchLocation) {
      if (request.locationNameFilter != 'Current Map Area') {
        initialSearchLocation = true;
      }
    }

    if (existingRequest) {
      existingRequest = { ...existingRequest, ...request };
    } else {
      existingRequest = request;
    }

    this._store.update({ initialSearch });
    this._store.update({ initialSearchLocation });
    this._store.update({ opensearchRequest: existingRequest });
  }

  public getOpensearchVendors(searchFilters: GetOpensearchVendorRequest): void {
    this.setTriggerOrigin();

    this._store.setSearching(true);

    const existingRequest = this._store.getValue()?.opensearchRequest;

    let location: ISearchLocation = {};

    if (existingRequest && existingRequest.userLocation) {
      location = {
        center: [
          existingRequest.userLocation.lat,
          existingRequest.userLocation.lon
        ]
      };
    }

    this._store.update({ request: {} });

    this._store.update({
      request: {
        results_limit: searchFilters.size,
        text: searchFilters.filter,
        location
      }
    });

    if (existingRequest) {
      searchFilters = { ...searchFilters, ...existingRequest };
    }

    if (!searchFilters.size || searchFilters.size == 0)
      searchFilters.size = 10000;

    const initialSearch = this._store.getValue()?.initialSearch ?? false;

    this._store.update({ opensearchRequest: searchFilters });

    this._api
      .getOpensearchResults(searchFilters, initialSearch)
      .pipe(this._addLogos, this._updateConnectionStatus)
      .subscribe(
        (results: SupplierListModel[]) => {
          this.setDiscoverResult(results);
          this._store.setSearching(false);
          this.removeTriggerOrigin();
        },
        () => {
          this._store.setSearching(false);
        }
      );
  }

  public updateMapCenter(center: [number, number]): void {
    this._mapSettingStore.update((value) => ({
      ...value,
      mapConfiguration: {
        ...(value?.mapSetting ? value?.mapSetting : { radius: 0 }),
        center
      }
    }));
  }

  public changeViewType(): void {
    this._mapSettingAction.toggleViewType();
  }

  private setDiscoverResult(results: SupplierListModel[]): void {
    this.capabilityQuery.select().subscribe(() => {
      this._store.update(() => ({
        hasMoreVendors: true,
        resultsCount: results.length,
        results: results
      }));
    });
  }

  public setConnectionRequested(sustainmentId: string): void {
    const values = this._store.getValue();
    const results = { total: values.resultsCount, data: [...values.results!] };
    const toUpdate = results.data.findIndex(
      (v) => v.sustainmentId === sustainmentId
    );
    if (toUpdate < 0) return;

    const updated = { ...results.data[toUpdate] };
    updated.connectionRequested = true;
    results.data[toUpdate] = updated;

    this._store.update({ ...values, ...results });
  }

  public setInvited(odyssesusId: string): void {
    const values = this._store.getValue();
    const results = { total: values.resultsCount, data: [...values.results!] };
    const toUpdate = results.data.findIndex(
      (v) => v.sustainmentId === odyssesusId
    );
    if (toUpdate < 0) return;

    const updated = { ...results.data[toUpdate] };
    updated.inviteRequested = true;
    results.data[toUpdate] = updated;

    this._store.update({ ...values, ...results });
  }

  public clearRequest(): void {
    this._store.reset();
  }

  private removeTriggerOrigin(): void {
    let existingRequest = this._store.getValue()?.opensearchRequest;

    if (existingRequest?.triggeredFrom) {
      existingRequest = { ...existingRequest, triggeredFrom: '' };

      this._store.update({ opensearchRequest: existingRequest });
    }
  }

  private setTriggerOrigin(): void {
    const url = this._router.url.substring(1, this._router.url.length);

    let existingRequest = this._store.getValue()?.opensearchRequest;

    if (existingRequest?.triggeredFrom) return;

    let triggeredFrom: string;

    switch (url) {
      case 'discover':
      case 'network':
        triggeredFrom = 'Network';
        break;
      case 'dashboard':
        triggeredFrom = 'Dashboard';
        break;
      default:
        triggeredFrom = 'Modal';
        break;
    }

    if (existingRequest) {
      existingRequest = { ...existingRequest, triggeredFrom };
    } else {
      existingRequest = { triggeredFrom } as GetOpensearchVendorRequest;
    }

    this._store.update({ opensearchRequest: existingRequest });
  }
}
