import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IStateData, IStatesState } from 'sustainment-component';
import { StateStore } from './state.store';

@Injectable({ providedIn: 'root' })
export class StateQuery extends Query<IStatesState> {
  public constructor(protected store: StateStore) {
    super(store);
  }

  public getByNameOrAbbreviation(
    nameOrAbbreviation: string
  ): IStateData | undefined {
    return this.getValue()?.states?.find(
      (s) =>
        s.name.toLowerCase() === nameOrAbbreviation.toLowerCase() ||
        s.abbreviation.toLowerCase() === nameOrAbbreviation.toLowerCase()
    );
  }

  public getByStateId(stateId: number): IStateData | undefined {
    return this.getValue()?.states?.find((s) => s.stateId === stateId);
  }
}
