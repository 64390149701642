import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  combineLatest,
  concat,
  forkJoin,
  Observable,
  of,
  ReplaySubject
} from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  shareReplay,
  startWith,
  tap
} from 'rxjs/operators';
import {
  InteractionsApi,
  PicturesAPI,
  VendorAPI,
  VendorFormatService,
  IVendorDocumentBase,
  IVendorPicture,
  IVendorPictureBase,
  SizeCode,
  VendorModelResponse,
  VendorProfile,
  VendorState
} from 'sustainment-component';
import { ConnectionStatus } from 'sustainment-component/lib/types/interactions';

@Injectable({ providedIn: 'root' })
export class VendorService {
  public constructor(
    private _sanitizer: DomSanitizer,
    private _apiVendor: VendorAPI,
    private _interactionsApi: InteractionsApi,
    private _vendorFormatService: VendorFormatService,
    private _pictureAPI: PicturesAPI
  ) {}

  public loadVendor(id: string, loadData = true): Observable<VendorState> {
    const subject = new ReplaySubject<VendorState>(1);

    const vendorData$ = this._apiVendor.getVendorData(id);

    const vendorConnectionStatus$ = vendorData$.pipe(
      mergeMap((vendor) =>
        this._interactionsApi.getConnectionStatus(id).pipe(
          catchError(
            (): Observable<ConnectionStatus> => of({ status: 'None', code: '' })
          ),
          map((is) => ({
            ...vendor,
            connected: is.status === 'Connected',
            inviteRequested: is.status === 'InviteRequested',
            connectionRequested: is.status === 'ConnectionRequested',
            wantsToConnect: is.status === 'WantsToConnect'
          }))
        )
      )
    );

    const initVendorState$ = vendorConnectionStatus$.pipe(
      tap((v) =>
        subject.next({
          vendor: this.calculateVendorState(v),
          pictures: [],
          pdfDocuments: []
        })
      )
    );

    let thumbnails$: Observable<IVendorPicture[]>;
    let pdfData$: Observable<IVendorDocumentBase[]>;
    if (loadData) {
      const loadingPictures$ = this._apiVendor.getVendorPicturesById(id).pipe(
        startWith<IVendorPictureBase[]>([]),
        map((pictures) => {
          return pictures.map((p) => ({
            ...p,
            url: '/assets/icons/loading.gif'
          }));
        }),
        shareReplay(1)
      );

      thumbnails$ = loadingPictures$.pipe(
        mergeMap((pictures) => forkJoin(this.getThumbnails(pictures, id)))
      );
      thumbnails$ = concat(loadingPictures$, thumbnails$);
      pdfData$ = this._apiVendor.getVendorDocumentsById(id).pipe(startWith([]));
    } else {
      thumbnails$ = of([]);
      pdfData$ = of([]);
    }

    const vendorState$ = combineLatest([
      initVendorState$,
      thumbnails$,
      pdfData$
    ]).pipe(
      map(([vendor, thumbnails, pdfData]) => {
        return {
          vendor: this.calculateVendorState(vendor),
          pictures: thumbnails,
          pdfDocuments: pdfData
        };
      }),
      tap((state) => subject.next(state))
    );

    vendorState$.subscribe({ complete: () => subject.complete() });

    return subject.asObservable();
  }

  public getThumbnails(
    pictures: IVendorPictureBase[],
    sustainmentId: string
  ): Observable<IVendorPicture>[] {
    return pictures.map((p: IVendorPictureBase) =>
      this._pictureAPI
        .getPicture(p.externalId, sustainmentId, SizeCode.Thumbnail)
        .pipe(
          catchError(() => of('default.jpg')),
          map((b: Blob | string): IVendorPicture => {
            const url = b instanceof Blob ? URL.createObjectURL(b) : b;
            return { ...p, url: this._sanitizer.bypassSecurityTrustUrl(url) };
          })
        )
    );
  }

  //TODO: we need the type, not the same when own profile vs someone else
  private calculateVendorState(vendor: VendorModelResponse): VendorProfile {
    return {
      ...vendor,
      certifications: vendor.certifications || [],
      classifications: vendor.classifications || [],
      logoUrl: this._vendorFormatService.getLogo(
        vendor.sustainmentId!,
        vendor.logo!
      ),
      coverUrl: this._vendorFormatService.getCover(
        vendor.sustainmentId!,
        vendor.cover!
      )
    } as unknown as VendorProfile;
  }
}
